<script>
//import { required } from "vuelidate/lib/validators";

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {getAccountingGLApi} from '@/api/accounting/query'

import FsGlEditDialog from './components/fs_gl_edit_dialog.vue'


/**
 * User list component
 */
export default {
  page: {
    title: "General Ledger List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      listingData: [],
      title: "General Ledger List",
      items: [
        {
          text: "Setting",
          href: "/",
        },
        {
          text: "General Ledger",
          active: true,
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 200,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      sortBy: "value",
      sortDesc: false,
      fields: [
        {
          key: "ID",
          sortable: true,
        },
        {
          key: "Account",
          sortable: true,
        },
        {
          key: "Sign",
          sortable: true,
        },
        {
          key: "FSCode",
          sortable: true,
        },
        {
          key: "Description",
          sortable: true,
        },
        
        {
          key: "Action",
          sortable: true,
        },
      ],
      submitted: false,

      gl : {
        account : '',
        sign: '+'
      }
    };
  },
  components: {
    Layout,
    PageHeader,
    FsGlEditDialog
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },
  },

  created() {
    this.queryList()
  },
  mounted() {
  
  },
  methods: {

    queryList() {
      getAccountingGLApi().gl_list({page : {page : this.currentPage, page_size: this.perPage}}).then((res)=>{
        this.listingData = []
        res.data.map((o) =>{
        
          this.listingData.push({
            id: o.id,
            account :o.account,
            sign : o.sign,
            description : o.description,
            class_type :o.class_type,
          })
          this.totalRows = res.page.total;
        })  

      })
    },

    pageChange(p) {
      this.currentPage = p
      this.queryList()
    },
   

    gl_save(new_gl) {
      console.log(new_gl)

      if (!new_gl.id) {
        getAccountingGLApi().gl_new(new_gl).then((res) => {
          if (res.errCode == 0) {
            this.queryList()
            this.$bvModal.hide('modal-gl-edit-dialog')
          } else {
            this.$alertify.error("Failed to Create GL  " + res.errCode);
          }
        })
      } else {
        getAccountingGLApi().gl_update(new_gl).then((res) => {
          if (res.errCode == 0) {
            this.queryList()
            this.$bvModal.hide('modal-gl-edit-dialog_'+new_gl.id)
          } else {
            this.$alertify.error("Failed to Create GL  " + res.errCode);
          }
        })
      }
    }

  },
  validations: {
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-2">
                <div class="mb-3">
                  <h5 class="card-title">
                    General Ledger 
                    <span class="text-muted fw-normal ms-2">({{ this.totalRows }})</span>
                  </h5>
                </div>
              </div>
              <!-- end col -->
              <div class="col-md-4">
               <input v-model="filter"  class="form-control" placeholder="Search General Ledger"/>
              </div>

              <div class="col-md-6">
                <div
                  class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3"
                >
                 
                  <b-dropdown
                    variant="white"
                    right
                    toggle-class="btn-link text-dark shadow-none"
                  >
                    <template v-slot:button-content>
                      <i class="uil uil-ellipsis-h"></i>
                    </template>
                    <b-dropdown-item v-b-modal.modal-gl-edit-dialog>Add New GL</b-dropdown-item>
                  </b-dropdown>
                  <!-- end dropdown -->
                  <b-modal centerd :id="'modal-gl-edit-dialog'" title="General Ledger" size="lg" hide-footer>
                    <FsGlEditDialog  :gl="gl" @cancel="$bvModal.hide('modal-gl-edit-dialog')" @confirm="gl_save"  />
                  </b-modal>
                  
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table
                  :items="listingData"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  class="table-check"
                  :filter="filter"
                  :filter-included-fields="['account', 'description']"
                  @filtered="onFiltered"
                >

                  <template  #cell(ID)="data">
                    {{ data.item.id}}
                  </template>
                  <template #cell(Account)="data"> 
                    {{ data.item.account}}
                  </template>
                  <template #cell(Sign)="data">
                    {{ data.item.sign}}
                  </template>
                  <template #cell(FSCode)="data">
                    {{ data.item.fs_code}}
                  </template>
                  <template #cell(Description)="data">
                    {{ data.item.description }}
                  </template>
                
                  <template #cell(action)="data">
                    <b-dropdown variant="light" right size="sm">
                      <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                      </template>
                      <b-dropdown-item @click="$bvModal.show('modal-gl-edit-dialog_'+data.item.id)" >Edit</b-dropdown-item>
                    </b-dropdown>
                    <b-modal centerd :id="'modal-gl-edit-dialog'+'_'+data.item.id" title="General Ledger" size="lg" hide-footer>
                    <FsGlEditDialog  :gl="data.item" @cancel="$bvModal.hide('modal-gl-edit-dialog_'+data.item.id)" @confirm="gl_save"  />
                  </b-modal>
                  </template>

                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start">
              <!-- end col -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-end"
                    >
                      <!-- pagination -->
                      <b-pagination
                        class="pagination-rounded"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        @change="pageChange"
                      ></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
