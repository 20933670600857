<script>


/**
 * Basic Info Edit component
 */


 import {
  required,
} from "vuelidate/lib/validators";
import Choices from "choices.js";



export default {

    emits: ['confirm', 'cancel'],

    props: ['gl'],
    components: {
        
    },

    validations: {
        gl: {
            account: { required },
        },
    },

    

    data() {
      
        return {
            submitted : false,
            gl_choice: {},
            liability_gl_choice:{} ,
        }
       
    },
    methods: {
        formSubmit() {
            this.submitted = true;     
            this.$v.$touch();     
            if (this.$v.$error == false) {     
                this.$emit('confirm', this.gl)
            }
        },

    
    },

    created() {
     
       
    },

    mounted() {

        this.sign_choice = new Choices('#sign_choice', {
            placeholderValue: "This is a placeholder set in the config",
            searchPlaceholderValue: "search by name",
        
        })

        new Choices('#class_choice', {
            placeholderValue: "This is a placeholder set in the config",
            searchPlaceholderValue: "search by name",
        
        })
        

    }

};
</script>

<template>
    <div class="card">
        <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">General Ledger</h4>
        </div>
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom01">Account</label>
                            <input id="validationCustom01" v-model="gl.account" type="text"
                                class="form-control" placeholder="" value="" :class="{
                                    'is-invalid': submitted && $v.gl.account.$error,
                                }" />
                            <div v-if="submitted && $v.gl.account.$error" class="invalid-feedback">
                                <span v-if="!$v.gl.account.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <div class="mb-3">
                                <label for="sign_choick">Sign</label>
                                <select
                                    class="form-control"
                                    data-trigger
                                    v-model="gl.sign"
                                    name="sign_choice"
                                    id="sign_choice"
                                    >
                                    <option value="+" selected>+</option>
                                    <option value="-">-</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="mb-3">
                            <div class="mb-3">
                                <label for="class_choick">Class</label>
                                <select
                                    class="form-control"
                                    data-trigger
                                    v-model="gl.class_type"
                                    name="class_choice"
                                    id="class_choice"
                                    >
                                    <option value="A">Asset</option>
                                    <option value="L" selected>Liability</option>
                                    <option value="O">Other</option>
                                    <option value="X">Expense</option>
                                    <option value="S">Rev/Sales</option>

                                </select>
                            </div>
                        </div>
                    </div>
                  
                </div>
                 <!-- end row-->

                <div class="row">
                    <div class="col-md-12">
                        <div class="mb-3">
                            <div class="mb-3">
                                <label for="description">Description</label>
                                <input id="description" v-model="gl.description" type="text"
                                class="form-control" placeholder="$" value=""  />
                            </div>
                        </div>
                    </div>
                </div>
                 <!-- end row-->


                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button  variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>